import ApexCharts from 'apexcharts'

import { Controller } from "@hotwired/stimulus"

// https://apexcharts.com/docs/chart-types/area-chart/
export default class extends Controller {
  static targets = ["overviewChart"]

  static values = {
    chartData: { type: Object },
    chartKey: { type: String, default: "total_users" }
  }

  connect() {
    var chart = new ApexCharts(this.overviewChartTarget, this.parseData());

    chart.render();
  }

  parseData() {
    const header = this.chartDataValue.headers.find(header => header.key === this.chartKeyValue);

    const series = this.chartDataValue.rows.map(row => {
      return {
        d: row[0].value,
        x: new Date(row[0].value).getTime(),
        y: row.find(cell => cell.key === header.key).value
      };
    })

    return {
      chart: {
        height: 350,
        type: "area",
        stacked: true,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ["#247BA0"],
      series: [
        {
          name: header.display_name,
          data: series.sort((a, b) => a.x - b.x)
        },
      ],
      stroke: {
        width: [2, 2],
        curve: 'monotoneCubic'
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.9,
          opacityTo: 0.1,
        }
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: [
        {
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: "#FF1654"
          },
          labels: {
            style: {
              colors: "#FF1654"
            }
          },
          title: {
            text: header.display_name,
            style: {
              color: "#FF1654"
            }
          }
        }
      ],
      tooltip: {
        show: false,
        shared: false,
        intersect: true,
        x: {
          show: true
        }
      },
      legend: {
        horizontalAlign: "left",
        offsetX: 40
      }
    }
  }
}

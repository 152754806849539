import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    gClientId: String,
    gScopes: String
  }

  static targets = ["gBtn"]

  connect() {
    window.gisLoaded = this.gisReady.bind(this);

    this.gisReady();
  }

  // Google Identity Services are loaded.
  gisReady() {
    if (!window.google) {
      setTimeout(() => { this.gisReady(); }, 200);
      return;
    }

    console.log("Google Identity Services are loaded.");

    google.accounts.id.initialize({
      client_id: this.gClientIdValue,
      // scope: this.gScopesValue,
      callback: this.googleSigninCallback.bind(this)
    });

    this.enableGoogleButton();
  }

  enableGoogleButton() {
    this.gBtnTarget.classList.remove("hidden");

    google.accounts.id.renderButton(this.gBtnTarget, {theme: "filled_blue"});
  }

  googleSignin(e) {
    e.preventDefault()

    google.accounts.id.prompt();
  }

  googleSigninCallback(resp) {
    console.log("Google Signin Callback");

    if (resp.error !== undefined) {
      throw (resp);
    }

    fetch("/auth/google_oauth2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify(resp)
    })
    .then(response => window.location.href = "/ga_properties")
  }
}
